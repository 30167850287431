@import 'core-styles/variables';

.final-permissions {
  margin-top: 20px;

  .label {
    margin-bottom: 10px;
    color: #000;
  }

  ul {
    li {
      display: inline-block;

      &.permission {
        padding: 5px 10px;
        border: 1px solid $border-light-color;
        border-radius: 4px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 13px;
      }
    }
  }
}

/* responsiveness */
@media (max-width: 992px) {}

@media (max-width: 762px) {}

@media (max-width: 520px) {}

/* /responsiveness */