@import "variables.scss";

$field-height: 45px;

body {
  font-family: $primary-font !important;
  background-color: #fff !important;
  top: 0 !important;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  margin-bottom: 0;

  li {
    list-style: none;
  }
}

p {
  margin-bottom: 0;
}

a {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $primary-color;
    filter: brightness(0.90);
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: $primary-color;
}

::selection {
  color: #fff;
  background: $primary-color;
}

.main-container {
  padding-top: 0;

  // &.secured {
  //   padding-top: 45px;
  // }

  &.no-header {
    padding-top: 0;
  }
}

.page {
  background: $screen-background;

  .page-title {
    font-size: 16px;
    color: $title-color;
    font-weight: 500;
    margin-bottom: 10px;
  }

  iframe {
    border: 1px solid $border-light-color;
  }
}

.container {
  width: 85%;
  margin: auto;
}

.fill {
  background: #fafafa !important;
}

/*button*/
.ant-btn {
  height: 48px;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 400;
  cursor: pointer;

  i {
    margin-right: 5px;
  }

  &>.anticon+span {
    margin-left: 0;
  }

  &.primary-btn {
    background: $primary-color;
    color: #fff;
    border: none;

    &:hover {
      filter: brightness(0.90);
    }
  }

  &.primary-btn-text {
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      border-color: $primary-color !important;
    }
  }

  &.red-btn-text {
    border: 1px solid $border-light-color;
    color: $brand-red;

    &:hover {
      border-color: $brand-red !important;
      color: $brand-red !important;
    }
  }

  &.disabled {
    background: #dadada !important;
    color: #fff !important;
  }
}

/*/button*/

/*table*/
.ant-table {
  .ant-table-content {
    .ant-table-body {
      .ant-table-tbody>tr:hover>td {
        background: transparent !important;
      }
    }
  }
}

/*/table*/

.ant-form {
  .ant-form-item {
    .ant-form-item-explain {
      line-height: 2;
    }
  }
}

/*drop down*/
.ant-dropdown {
  .ant-dropdown-menu {
    width: 200px;
    padding: 0;
    overflow: hidden;

    .ant-dropdown-menu-submenu {
      border-top: 1px solid $border-light-color;

      &:first-child {
        border-top: none;
      }

      .ant-dropdown-menu-submenu-title {
        padding: 10px 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        font-family: $primary-font;
        font-weight: 600;

        .ant-dropdown-menu-submenu-arrow {
          top: 15px;
        }

        &:hover {
          background-color: #fafafa;
          color: $primary-color;
        }
      }
    }
  }
}

.ant-dropdown-menu-submenu {
  .ant-dropdown-menu {
    width: 200px;
    padding: 0;
    overflow: hidden;

    .ant-dropdown-menu-item {
      padding: 10px 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 12px;
      font-family: $primary-font;
      font-weight: 600;
      border-top: 1px solid $border-light-color;

      &:hover {
        background-color: #fafafa;
      }
    }
  }
}

/*/drop down*/

/*check box*/
.ant-checkbox {

  .ant-checkbox-checked,
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
    }
  }
}

.ant-checkbox-checked::after {
  border: 1px solid $primary-color;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: $primary-color;
}

/*/check box*/

/*/popover*/
.ant-popover {
  .ant-popover-inner {
    border-radius: 10px;
    overflow: hidden;
  }
}

/*/popover*/

/*/select dropdown*/
.ant-select-dropdown {
  .ant-select-dropdown-menu-item {
    &:hover {
      background-color: $thumbnail-bg;
    }
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: $thumbnail-bg;
  }
}

.ant-select-selection--multiple {
  .ant-select-selection__placeholder {
    margin-left: 0;
  }
}

.ant-select {

  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none !important;
  }

  .ant-select-selection--multiple {
    min-height: 43px;
    padding-top: 6px;
    border: none;

    &:active {
      box-shadow: none;
    }

    .ant-select-selection__rendered {
      margin: 0;
    }
  }
}

/*/select dropdown*/

/*modal*/
.ant-modal {
  top: 30px;

  &.large {
    width: 98% !important;
  }

  .ant-modal-content {
    padding: 0px;

    .ant-modal-header {
      background: $sider-background;
      border-bottom: none;
      padding: 15px 24px;

      .ant-modal-title {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .ant-modal-body {
      padding: 20px;

      .actions {
        text-align: right;
        margin-top: 20px;

        button {
          margin-left: 10px;
        }
      }
    }

    .ant-modal-close-x {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.ant-modal-confirm {
    width: 415px !important;
  }

  &.small-width {
    width: 512px !important;
  }

  &.have-form-sections {
    .ant-modal-body {
      padding: 0;

      .ant-form {
        .form {
          .form-section {
            &:last-child {
              border-bottom: none;
            }
          }
        }

        .actions {
          margin-top: 0;
          padding: 24px;
        }
      }
    }
  }
}

/*/modal*/

/* ck editor */
.cke {
  border: 1px solid $border-light-color !important;
  box-shadow: none !important;
  border-radius: 8px;
  overflow: hidden;

  .cke_bottom {
    background: $thumbnail-bg;

    .cke_resizer {
      border-color: transparent #646464 transparent transparent;
    }
  }
}

.tox-fullscreen {
  .tox-tinymce {
    border-radius: 0;
  }
}

.tox:not(.tox-tinymce-inline) {
  .tox-edit-area__iframe {
    background-color: $screen-background;
  }

  .tox-editor-header {
    box-shadow: none !important;
    border-bottom: 1px solid $border-light-color !important;
  }
}

/* /ck editor */

/* pagination */
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}


.ant-pagination-item-active {
  border-color: $primary-color;

  a {
    color: $primary-color;
  }
}

/* /pagination*/

.editor-content {
  a {
    color: $primary-color;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $title-color;
    font-family: $primary-font;
    font-weight: 600;
    line-height: 40px;
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    color: $text-color;
    margin-bottom: 20px;
  }

  ul,
  ol {
    margin-bottom: 20px;
    padding-left: 40px;

    li {
      list-style: inherit;
      margin-bottom: 10px;
    }

    ul {
      margin-left: 18px;
    }
  }

  strong {
    color: $title-color;
  }

  table {
    border: 1px solid $border-light-color;

    tr {
      border: 1px solid $border-light-color;

      th {
        font-weight: bold;
        font-size: 15px;
        padding: 10px 20px;
        border: 1px solid $border-light-color;
        color: $title-color;
      }

      td {
        border: 1px solid $border-light-color;
        padding: 10px 20px;
      }
    }
  }

  hr {
    border: 1px solid $border-light-color;
    margin: 20px 0;
  }

  img {
    max-width: 100%;
    border-radius: 10px;
  }

  .marker {
    background-color: #fafafa;
    padding: 4px 8px;
    border-radius: 4px;
    color: $title-color;
  }

  code {
    background-color: #fafafa;
    padding: 4px 0;
    border-radius: 4px;
    color: $title-color;
  }

  pre {
    max-width: 95%;
    background: #fafafa;
    border: 1px solid $border-light-color;
    text-shadow: none;
    box-shadow: none;
    font-size: 14px;
    padding: 20px;

    code {
      text-shadow: none;
    }
  }
}

.page-section {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .text {
      flex-grow: 2;
      flex-basis: 0;

      .title {
        font-size: 32px;
        color: $title-color;
        font-family: $primary-font;
        font-weight: 600;
        line-height: 40px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .description {
        font-size: 16px;
        letter-spacing: 1px;
        color: $text-color;
      }
    }

    .action {
      flex-grow: 1;
      flex-basis: 0;
      text-align: right;
    }
  }

  .section {
    padding: 90px 0;
  }

}

/*animations*/

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/**
  Added By Inder for general
*/
.brand-color-text {
  color: $primary-color !important;
}

/*/animations*/

/* spinner */
.spinner {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  -o-box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  -ms-box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  -webkit-box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  -moz-box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  animation: cssload-spin ease infinite 1.8s;
  -o-animation: cssload-spin ease infinite 1.8s;
  -ms-animation: cssload-spin ease infinite 1.8s;
  -webkit-animation: cssload-spin ease infinite 1.8s;
  -moz-animation: cssload-spin ease infinite 1.8s;
}

@keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
      -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
      12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }

  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
      12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
      -12px -12px $primary-color;
  }
}

@-o-keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
      -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
      12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }

  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
      12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
      -12px -12px $primary-color;
  }
}

@-ms-keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
      -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
      12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }

  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
      12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
      -12px -12px $primary-color;
  }
}

@-webkit-keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
      -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
      12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }

  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
      12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
      -12px -12px $primary-color;
  }
}

@-moz-keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
      -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
      12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }

  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
      12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
      -12px -12px $primary-color;
  }
}

/* /spinner */

/* responsiveness */
@media (min-width: 1600px) {
  .container {
    width: 1500px;
  }
}

@media (max-width: 767px) {
  .inline {
    display: block !important;

    .ant-form-item {
      padding: 0 !important;
    }

    .ant-row {
      padding: 0 !important;
    }
  }

  .ant-modal {
    width: 100% !important;
    max-width: 100%;
    top: 0;
    padding: 0;
    margin: 0;

    .ant-modal-content {
      border-radius: 0;
      min-height: 100vh;
    }

    &.small-width {
      width: 100% !important;
    }
  }
}

/* /responsiveness */